import { TuneIcon } from '@biome-design-system/icons/TuneIcon';
import { kyruusFormatHTMLMessage, KyruusFormattedMessage } from '@kyruus/intl';
import CancelCircle from '@kyruus/react-ikons/CancelCircle';
import Theme from '@kyruus/ui-theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _startCase from 'lodash/startCase';
import React, { useEffect, useMemo, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { DISTANCE_ANY } from '../facet-panel/facet-list/location-facet';
import {
  FacetAllFiltersBiomeChip,
  FacetBiomeChip,
  FacetChip,
  HorizontalFilters,
  RemoveFilterChipButton,
  StyledChipsContainer
} from '../styles';
import { useFocusManager } from './hooks/useFocusManager';
import { getTabIndex } from './utils';

const messages = defineMessages({
  removeFilterChipLabel: {
    id: 'facet_chips.remove_filter',
    description: 'Accessibility label for removing a search facet chip',
    defaultMessage: 'Remove filter'
  },
  locationFilterChipLabel: {
    id: 'facet_chips.location',
    description: 'Summary of search location information',
    defaultMessage:
      'Within {distance, plural, =Any {any miles} one {# mile} other {# miles}} of {location}'
  },
  allFiltersChipLabel: {
    id: 'facet_chips.all_filters',
    description: 'Label for all filters chip',
    defaultMessage: 'All Filters'
  }
});

const AllFiltersChipBase = ({ label, onClick }) => {
  return (
    <FacetAllFiltersBiomeChip
      role="group"
      tabIndex={-1}
      data-testid={`all-filters-chip`}
      icon={<TuneIcon style={{ color: 'inherit' }} />}
      label={<KyruusFormattedMessage {...label} />}
      clickable={true}
      onClick={onClick}
    />
  );
};

const AppliedChipBaseComponent = ({
  FacetChipComponent,
  filter,
  getUpdatedSearch,
  history,
  intl,
  onFocus,
  tabIndex
}) => {
  const facetLabelMessageDescriptor = {
    id: `field.name.${filter.field}`,
    defaultMessage: _startCase(filter.field),
    description: 'The name displayed above the facet'
  };
  const baseFacetMessageDescriptor = {
    id: `field.value.${filter.field}.${filter.term.value}`,
    defaultMessage: `${filter.term.value}`,
    description: 'Facet for provider search'
  };
  const chipLabel = `${kyruusFormatHTMLMessage(
    intl,
    facetLabelMessageDescriptor
  )}: ${kyruusFormatHTMLMessage(intl, baseFacetMessageDescriptor)}`;
  const removeChipLabel = kyruusFormatHTMLMessage(
    intl,
    messages.removeFilterChipLabel
  );

  return (
    <FacetChipComponent
      color="primary"
      role="group"
      tabIndex={-1}
      data-testid={`filter-chip-${filter.field}-${filter.term.value}`}
      label={
        <span
          aria-hidden="true"
          id={`filter-chip-${filter.field}-${filter.term.value}`}
        >
          {chipLabel}
        </span>
      }
      onDelete={() =>
        history.push(
          getUpdatedSearch([
            {
              action: 'delete_key_value',
              key: 'filter',
              value: `${filter.field}:${filter.term.value}`
            }
          ])
        )
      }
      deleteIcon={
        <RemoveFilterChipButton
          mode="primary"
          role="button"
          onFocus={onFocus}
          tabIndex={tabIndex}
          aria-describedby={`filter-chip-${filter.field}-${filter.term.value}`}
          aria-label={removeChipLabel}
        >
          <CancelCircle
            size="1.6em"
            aria-hidden="true"
            title={removeChipLabel}
          />
        </RemoveFilterChipButton>
      }
    />
  );
};

const AppliedChipBase = (props) => (
  <AppliedChipBaseComponent FacetChipComponent={FacetChip} {...props} />
);

const AppliedBiomeChipBase = (props) => (
  <AppliedChipBaseComponent FacetChipComponent={FacetBiomeChip} {...props} />
);

const LocationAppliedChipBaseComponent = ({
  FacetChipComponent,
  getUpdatedSearch,
  history,
  intl,
  onFocus,
  searchSummary,
  tabIndex
}) => {
  const chipLabel = kyruusFormatHTMLMessage(
    intl,
    messages.locationFilterChipLabel,
    {
      location: searchSummary.display_location ?? searchSummary.location,
      distance: searchSummary.distance || DISTANCE_ANY
    }
  );
  const removeChipLabel = kyruusFormatHTMLMessage(
    intl,
    messages.removeFilterChipLabel
  );

  return (
    <FacetChipComponent
      color="primary"
      role="group"
      tabIndex={-1}
      data-testid="filter-chip-location"
      label={
        <span aria-hidden="true" id="filter-chip-location-content">
          {chipLabel}
        </span>
      }
      onDelete={() =>
        history.push(
          getUpdatedSearch([
            { action: 'delete_key', key: 'display_location' },
            { action: 'delete_key', key: 'location' },
            { action: 'delete_key', key: 'distance' }
          ])
        )
      }
      deleteIcon={
        <RemoveFilterChipButton
          onFocus={onFocus}
          tabIndex={tabIndex}
          mode="primary"
          role="button"
          aria-describedby="filter-chip-location-content"
          aria-label={removeChipLabel}
        >
          <CancelCircle
            size="1.6em"
            aria-hidden="true"
            title={removeChipLabel}
          />
        </RemoveFilterChipButton>
      }
    />
  );
};

const LocationAppliedChipBase = (props) => (
  <LocationAppliedChipBaseComponent FacetChipComponent={FacetChip} {...props} />
);

const LocationAppliedBiomeChipBase = (props) => (
  <LocationAppliedChipBaseComponent
    FacetChipComponent={FacetBiomeChip}
    {...props}
  />
);

const AllFiltersChip = withRouter(injectIntl(AllFiltersChipBase));
const AppliedChip = withRouter(injectIntl(AppliedChipBase));
const AppliedBiomeChip = withRouter(injectIntl(AppliedBiomeChipBase));
const LocationAppliedChip = withRouter(injectIntl(LocationAppliedChipBase));
const LocationAppliedBiomeChip = withRouter(
  injectIntl(LocationAppliedBiomeChipBase)
);

const isHiddenFacet = (facet) =>
  [
    'location',
    'locations.primary_marketable_location_id',
    'locations.associated_marketable_location_ids',
    'provider.id'
  ].includes(facet.field);

const hasTermsAvailable = (facet) => !!facet?.terms?.length;

const shouldShowFacet = (facet) =>
  !isHiddenFacet(facet) && hasTermsAvailable(facet);

const FacetChipsComponent = ({
  AppliedChipComponent,
  LocationAppliedChipComponent,
  FiltersComponent,
  updatedFilterBar,
  facets,
  getUpdatedSearch,
  searchSummary,
  ...props
}) => {
  const [activeChip, setActiveChip] = useState(0);
  const { 'aria-orientation': ariaOrientation, onKeyDown } = useFocusManager({
    orientation: 'horizontal',
    itemElementRole: 'button'
  });
  const isScreenMediumOrBelow = useMediaQuery(
    `(max-width:${Theme.screen_medium})`
  );
  const appliedFilters = useMemo(
    () =>
      facets.filter(shouldShowFacet).reduce((acc, { field, terms }) => {
        terms
          .filter((term) => term.applied)
          .forEach((term) => acc.push({ field, term }));
        return acc;
      }, []),
    [facets]
  );

  // Reset active chip
  useEffect(() => {
    setActiveChip(0);
  }, [appliedFilters.length]);

  const showLocationChip = searchSummary.location;

  const allFacetChips = appliedFilters.map((filter, idx) => {
    const index = showLocationChip ? idx + 1 : idx;
    const selected = activeChip === index;
    const tabIndex = getTabIndex(activeChip, selected, index);
    return (
      <AppliedChipComponent
        filter={filter}
        getUpdatedSearch={getUpdatedSearch}
        key={filter.term.filter_param}
        tabIndex={tabIndex}
        onFocus={() => setActiveChip(index)}
      />
    );
  });

  const locationChipIndex = 0;
  const locationChipSelected = activeChip === locationChipIndex;
  const locationChipTabIndex = getTabIndex(activeChip, locationChipSelected, 0);

  const locationAppliedChips = showLocationChip && (
    <LocationAppliedChipComponent
      searchSummary={searchSummary}
      getUpdatedSearch={getUpdatedSearch}
      tabIndex={locationChipTabIndex}
      onFocus={() => setActiveChip(locationChipIndex)}
    />
  );

  let countOfAppliedFilters = new Set(
    appliedFilters.map((filter) => filter.field)
  ).size;
  if (showLocationChip) {
    countOfAppliedFilters++;
  }

  let label = messages.allFiltersChipLabel;
  if (!isScreenMediumOrBelow && countOfAppliedFilters > 0) {
    label = {
      ...label,
      defaultMessage: `${label.defaultMessage} • ${countOfAppliedFilters}`
    };
  }

  return (
    <FiltersComponent
      role="toolbar"
      aria-orientation={ariaOrientation}
      onKeyDown={onKeyDown}
      tabIndex={-1}
      onBlur={() => setActiveChip(0)}
    >
      {updatedFilterBar && (
        <AllFiltersChip label={label} onClick={props.onAllFilterClick} />
      )}
      {isScreenMediumOrBelow || (
        <>
          {locationAppliedChips}
          {allFacetChips}
        </>
      )}
    </FiltersComponent>
  );
};

const FacetChips = (props) => (
  <FacetChipsComponent
    AppliedChipComponent={AppliedChip}
    LocationAppliedChipComponent={LocationAppliedChip}
    FiltersComponent={StyledChipsContainer}
    updatedFilterBar={false}
    {...props}
  />
);

const FacetBiomeChips = (props) => (
  <FacetChipsComponent
    AppliedChipComponent={AppliedBiomeChip}
    LocationAppliedChipComponent={LocationAppliedBiomeChip}
    FiltersComponent={HorizontalFilters}
    updatedFilterBar={true}
    {...props}
  />
);

export { FacetBiomeChips, FacetChips, shouldShowFacet };
